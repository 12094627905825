<template>
    <v-container class="mt-10">
        <!-- Header -->
        <v-row class="d-flex align-center justify-space-between mb-2 titlepage">
            <h1 class="title-custom">Stock Management</h1>
            <v-btn color="primary"  class="elevation-0 btn-top-table elevation-0" @click="navigateToAddStock">ADD STOCK</v-btn>
        </v-row>
        <v-card class="mt-5 custom-rounded-card pa-4">

            <!-- Search and Filter -->
            <v-row class="mb-1">
                <v-col cols="12" md="3">
                    <v-text-field
                            v-model="search"
                            label="Search stock"
                            prepend-inner-icon="mdi-magnify"
                            class="small-text-field search_id"
                            solo
                            dense
                            hide-details
                            single-line
                    >
                        <template v-slot:prepend-inner>
                            <v-img
                                    :src=icon_search
                            ></v-img>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" md="5" class="d-flex justify-space-between align-center ">
                    <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                    v-model="dateRange"
                                    label="Filter by date"
                                    prepend-inner-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    solo
                                    class="small-text-field search_date"
                                    dense
                                    hide-details
                                    single-line
                            >
                                <template v-slot:prepend-inner>
                                    <v-img
                                            :src=icon_calendar
                                    ></v-img>
                                </template>
                            </v-text-field>
                        </template>

                        <v-container>
                            <v-row>
                                <v-col cols="12" :hidden="showStarDateEnabled">
                                    <v-text-field
                                            v-model="startDate"
                                            label="Start Date"
                                            prepend-inner-icon="mdi-calendar"
                                            readonly
                                            class="small-text-field"
                                            clearable
                                            dense
                                            hide-details
                                            single-line
                                    >
                                        <template v-slot:prepend-inner>
                                            <v-img
                                                    class="mt-1"
                                                    :src=icon_calendar
                                            ></v-img>
                                        </template>
                                    </v-text-field>
                                    <v-date-picker v-model="startDate" @input="updateStartDate"  class="mt-0"/>
                                </v-col>
                                <v-col cols="12" :hidden="showEndDateEnabled">
                                    <v-text-field
                                            v-model="endDate"
                                            label="End Date"
                                            prepend-inner-icon="mdi-calendar"
                                            readonly
                                            class="small-text-field"
                                            clearable
                                            @blur="validateDates"
                                            dense
                                            hide-details
                                            single-line
                                    >
                                        <template v-slot:prepend-inner>
                                            <v-img
                                                    class="mt-1"
                                                    :src=icon_calendar
                                            ></v-img>
                                        </template>
                                    </v-text-field>
                                    <v-date-picker v-model="endDate" @input="updateEndDate" :disabled="!isEndDateEnabled" class="mt-0" />
                                </v-col>
                            </v-row>
                            <v-row justify="end" class="mt-2">
                                <v-col cols="auto">
                                    <v-btn color="primary" @click="confirmDates">OK</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-menu>

                    <div class="d-flex mt-n0">
                        <v-btn color="primary" class="mx-1 ml-4 small-text-field filter_button elevation-0" @click="filterTable"
                               clearable solo dense hide-details single-line>Filter</v-btn>
                        <v-btn outlined @click="clearFilter"
                               class="small-text-field clear_button elevation-0"
                               clearable solo dense hide-details single-line>
                            <h4 style="color: #3867D3;">Clear</h4>
                        </v-btn>
                    </div>
                </v-col>
                <v-col cols="12" md="4" class="d-flex align-center">
                    <v-spacer></v-spacer>
                    <span @click="exportToCSV" class="export-link text-export-csv">
                        Export to CSV
                        <v-img
                                class="ml-2 mt-n2"
                                :src=logo_download
                                contain
                                height="40"
                        ></v-img>
                    </span>
                    <v-progress-circular
                            v-if="loadingExcel"
                            indeterminate
                            color="primary"
                            class="ml-2"
                            size="24"
                    ></v-progress-circular>
                </v-col>
            </v-row>

            <!-- Table -->
            <v-data-table
                    :headers="headers"
                    :items="stocks"
                    :search="search"
                    class="elevation-1 custom-data-table"
                    hide-default-footer
                    :loading="loading"
            >
                <template v-slot:loading>
                    <v-row justify="center" align="center">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </v-row>
                </template>

                <template v-slot:item.details="{ item }">
                    <v-btn icon @click="viewDetails(item)">
                        <v-img
                                :src=logo_detail
                                contain
                                height="40"
                        ></v-img>
                    </v-btn>
                    <v-btn icon @click="editItem(item)">
                        <v-img
                                :src=logo_update
                                contain
                                height="40"
                        ></v-img>
                    </v-btn>
                    <v-btn icon @click="checkFormValidity(item)">
                        <v-img
                                :src=icon_delete
                                contain
                                height="40"
                        ></v-img>
                    </v-btn>
                </template>

                <template v-slot:item.type="{ item }">
                    <span :class="item.type === 'Masuk' ? 'text-success' : 'text-danger'">
                        ● {{ item.type }}
                    </span>
                </template>
            </v-data-table>

            <!-- Pagination -->
            <v-container fluid>
                <v-row align="center" class="mt-4"> <!-- Ensure items are aligned in the center vertically -->
                    <!-- Left column: "Show X from Y data" -->
                    <v-col cols="6" class="d-flex justify-start show_page">
                        Show {{ displayedItemCount }} from {{ totalItems }} data
                    </v-col>

                    <!-- Right column: Pagination -->
                    <v-col cols="6" class="d-flex justify-end">
                        <v-pagination
                                v-model="page"
                                :length="pageCount"
                                @input="fetchStockData"
                                class="pagination_custom"
                                prev-icon="mdi-chevron-left"
                                next-icon="mdi-chevron-right"
                                prev-text="prev"
                                next-text="next"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </v-card>

        <!-- Confirmation Dialog -->
        <v-dialog v-model="confirmationDialog" max-width="400">
            <v-card class="elevation-12 rounded-lg">
                <v-card-title class="headline d-flex justify-center pb-2">
                    <v-icon color="primary" class="mr-2">mdi-alert-circle</v-icon>
                    Confirm Submission
                </v-card-title>

                <v-divider></v-divider> <!-- Divider for a cleaner separation -->

                <v-card-text class="text-center py-4">
                    {{confirmationDialogMessage}}
                </v-card-text>

                <v-card-actions class="d-flex justify-end px-4 pb-4">
                    <v-btn
                            color="grey lighten-1"
                            text
                            class="mr-4"
                            @click="confirmationDialog = false"
                    >
                        <v-icon left>mdi-cancel</v-icon>Cancel
                    </v-btn>
                    <v-btn
                            color="primary"
                            @click="deleteItem"
                    >
                        <v-icon left>mdi-check-circle</v-icon>Yes, Submit
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar
                v-model="successSnackbar"
                :color="color_snackbar"
                top
                timeout="3000"
        >
            {{pesan_snackbar}}
            <v-btn text @click="successSnackbar = false">Close</v-btn>
        </v-snackbar>

    </v-container>
</template>

<script>
    import axios from 'axios';
    import url from  './../../url'

    export default {
        data() {
            return {
                logo_download: `${url}/images/icon_download.png`,
                logo_detail: `${url}/images/icon_detail.png`,
                logo_update: `${url}/images/icon_update.png`,
                icon_delete: `${url}/images/icon_delete.png`,
                icon_calendar: `${url}/images/calendar.png`,
                icon_search: `${url}/images/search.png`,
                search: '',
                filterDate: '',
                menu: false,
                showEndDateEnabled: true,
                showStarDateEnabled: false,
                isEndDateEnabled: false,
                page: 1,
                stocks: [],
                loading: false, // Loading state
                loadingExcel: false, // Loading state for Excel
                totalItems: 0, // Initialize totalItems
                fromtotalItems: 0,
                startDate: '',
                endDate: '',
                successSnackbar: false,
                color_snackbar: '',
                pesan_snackbar: '',
                confirmationDialog: false,
                confirmationDialogMessage: '',
                StockID: '',
                headers: [
                    { text: 'Stock ID', value: 'id' },
                    { text: 'Tanggal', value: 'date' },
                    { text: 'Source/Destination', value: 'source' },
                    { text: 'Kartu Hitam', value: 'kartuHitam' },
                    { text: 'Kartu Silver', value: 'kartuSilver' },
                    { text: 'Type Stock', value: 'type' },
                    { text: 'Details', value: 'details', sortable: false },
                ],
            };
        },
        computed: {
            pageCount() {
                return Math.ceil(this.totalItems / 7); // Assuming 7 items per page
            },
            dateRange() {
                return this.startDate && this.endDate
                    ? `${this.formatDate(this.startDate)} - ${this.formatDate(this.endDate)}`
                    : '';
            },
            displayedItemCount() {
                // return this.stocks.length; // Number of items currently displayed
                return this.fromtotalItems; // Number of items currently displayed
            },
        },
        created() {
            this.fetchStockData();
        },
        methods: {
            formatDate(dateString) {
                const date = new Date(dateString);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
                const year = date.getFullYear();
                return `${day}/${month}/${year}`; // Format: d/m/y
            },
            checkFormValidity(item) {
                if (item.id) {
                    // Show confirmation dialog if form is valid
                    this.StockID = item.id;
                    this.confirmationDialog = true;
                    this.confirmationDialogMessage = 'Apakah Anda yakin ingin menghapus data?'
                } else {
                    this.successSnackbar = true;
                    this.color_snackbar = 'red';
                    this.pesan_snackbar = "Tidak ada data stock ID";

                }
            },
            navigateToAddStock() {
                this.$router.push({ name: 'AddDelivery' }); // Replace with your route name
            },
            async fetchStockData() {
                this.loading = true; // Start loading
                try {
                    const params = {
                        page: this.page,
                        id: this.search,
                        start_date: this.startDate,
                        end_date: this.endDate,
                    };
                    const response = await axios.post('stock/list', params); // Replace with your actual API endpoint
                    const { data } = response.data.data;  // Ensure you capture total from response
                    this.stocks = data.map(item => ({
                        id: item.order_code,
                        date: item.tanggal,
                        source: item.source_destination,
                        kartuHitam: item.jmlh_hitam,
                        kartuSilver: item.jmlh_silver,
                        type: item.type_stock,
                    }));
                    this.fromtotalItems = response.data.data.to;  // Store the total number of items for pagination
                    this.totalItems = response.data.data.total;  // Store the total number of items for pagination
                } catch (error) {
                    console.error('Error fetching stock data:', error);
                } finally {
                    this.loading = false; // Stop loading
                }
            },
            filterTable() {
                this.fetchStockData(); // Fetch data based on the current filters
            },
            clearFilter() {
                this.startDate = '';
                this.endDate = '';
                this.search = '';
                this.page = 1; // Reset to first page
                this.fetchStockData();
            },
            viewDetails(item) {
                this.$router.push({ path: `/DetailDelivery/${item.id}` });
            },
            editItem(item) {
                this.$router.push({ path: `/UpdateDelivery/${item.id}` });
            },
            async deleteItem() {
                this.confirmationDialog = false;
                try {
                    const params = {
                        id: this.StockID,
                    };
                    const response = await axios.post('stock/destroy', params); // Replace with your actual API endpoint
                    const { error,message } = response.data;  // Ensure you capture total from response
                    if (error == false){
                        this.successSnackbar = true;
                        this.color_snackbar = 'success';
                        this.pesan_snackbar = message;
                        this.fetchStockData()
                    }
                } catch (error) {
                    console.error('Error fetching stock data:', error);
                }
            },
            async exportToCSV() {
                this.loadingExcel = true; // Start loading
                try {
                    const response = await axios({
                        url: 'stock/export', // Update with your actual endpoint
                        method: 'POST',
                        responseType: 'blob', // Important for downloading files
                    });

                    // Create a URL for the file
                    const url = window.URL.createObjectURL(new Blob([response.data]));

                    // Create a link element
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'stocks.csv'); // Specify the file name

                    // Append to the document and trigger download
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                } catch (error) {
                    console.error('Error downloading CSV:', error);
                } finally {
                    this.loadingExcel = false; // Stop loading
                }
            },
            updateStartDate(date) {
                this.startDate = date;
                this.isEndDateEnabled = true;
                this.showEndDateEnabled = false;
                this.showStarDateEnabled = true;
            },
            updateEndDate(date) {
                this.endDate = date;
                this.showEndDateEnabled= true;
                this.showStarDateEnabled= false;
                if (this.startDate && this.endDate) {
                    this.menu = false; // Close the menu
                } else {
                    this.validateDates(); // Validate if dates are filled
                }
            },
            validateDates() {
                if (!this.startDate || !this.endDate) {
                    alert('Both start and end dates are required!');
                    return;
                }
            },
            confirmDates() {
                if (this.startDate && this.endDate) {
                    this.menu = false; // Close the menu
                } else {
                    this.validateDates(); // Validate if dates are filled
                }
            }
        },
    };
</script>

<style>
    .export-link {
        cursor: pointer;
        display: flex;
        align-items: center;
        transition: color 0.3s ease;
    }

    .export-link:hover {
        color: #1976D2;
    }
    .clickable-icon {
        display: inline-flex;
        align-items: center;
    }

    .clickable-icon:hover .v-icon {
        transform: scale(1.1);
        transition: transform 0.2s ease;
    }

    .small-text-field {
        border-radius: 8px;
    }

    .small-text-field:focus {
        box-shadow: none;
    }

    .v-input__control {
        background-color: #f9f9f9;
    }

    .custom-rounded-card {
        border-radius: 16px;
    }

    .text-success {
        color: green;
    }

    .text-danger {
        color: red;
    }

    .bold-text {
        font-weight: bold;
    }
</style>
